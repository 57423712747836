@import "src/styles/colors"

.drawer-container
  background-color: $background-color
  padding: 29px 16px 16px
  display: flex
  flex-direction: column
  .tittle
    font-size: 16px
    font-weight: 500
  .description
    font-size: 14px
    padding-top: 16px
    &.medium
      font-size: 16px
      padding-top: 56px
  .stack
    padding-top: 16px
    .paper
      display: flex
      flex-direction: row
      align-items: center
      border-radius: 12px
      padding: 12px
      margin-top: 8px
      cursor: pointer
      .avatar
        width: 44px
        height: 44px
        background-color: $grey-bg
      span
        padding-left: 12px
        font-size: 16px
        font-weight: 500
  .btn-container
    padding-top: 29px
    display: flex
    flex: 1
    justify-content: end
    flex-direction: column
    button:nth-child(2)
      padding-top: 16px