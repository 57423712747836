@import "src/styles/colors"

.card-container
  position: relative
  flex-direction: row
  padding-bottom: 50px
  overflow: auto
  .back-icon
    position: absolute
    top: 0
    left: 0
  .image-container
    cursor: pointer
    padding-top: 56px
    display: flex
    flex: 1
    justify-content: center
    align-items: center
    flex-direction: column
    .change-photo
      cursor: pointer
      padding-top: 16px
      color: $blue-primary
      font-weight: 500
  .form-container
    display: flex
    flex: 1
    flex-direction: column
    .MuiTextField-root,
    .MuiFormControl-root
      margin: 16px 0