// global

@import "./styles/index"

.App
  display: flex
  flex: 1
  min-height: 100vh
  overflow: auto
  background: $background-color
