@import "src/styles/vars"

.card-container
  flex-direction: row
  .card
    overflow: hidden
    border-radius: 16px
    height: 216px
    position: relative
    display: flex
    flex: 1
    background-color: $blue-ocean
    .title
      display: flex
      position: absolute
      z-index: 10
      left: 16px
      top: 16px
      color: white
      font-weight: 500
      font-size: 16px
    .address
      display: flex
      flex-direction: row
      color: white
      border: 1px solid white
      border-radius: 12px
      position: absolute
      z-index: 10
      top: 16px
      right: 16px
      padding: 6px 6px
      align-items: center
      span
        font-weight: 500
        padding-left: 5px
    .clouds
      display: flex
      position: absolute
      right: 0
      bottom: 0
      height: 216px
    .avatar
      height: 100px
      width: 100px
      position: absolute
      top: calc(50% - 50px)
      left: calc(50% - 50px)
      z-index: 10
    .name-container
      z-index: 11
      position: absolute
      top: calc(50% + 66px)
      width: 100%
      justify-content: center
      text-align: center
      flex: 1
      .name
        font-weight: 500
        font-size: 16px
    .bottom
      width: 100%
      position: absolute
      bottom: 0
      display: flex
      flex: 1
      height: 108px
      background-color: $background-color
  .user-data
    flex-direction: column
    display: flex
    flex: 1
    background: white
    padding: 13px 0 13px 8px
    border-radius: 16px
    .row
      display: flex
      flex-direction: row
      justify-content: space-between
      padding-right: 16px
      .title
        font-size: 14px
        color: $grey-text
