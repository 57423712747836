.not-registered-container
  display: flex
  align-items: baseline
  flex-direction: column
  padding: 16px 0
  .not-connected
    font-size: 16px
    padding: 0 16px
    line-height: 24px
  h1
    font-size: 16px
    font-weight: 500
    padding: 16px 0
  div
    font-size: 16px
  .list
    list-style-type: disc
    padding-left: 32px
    li
      text-align: left
      font-size: 16px
      padding: 2px 0
